import Vue from "vue/dist/vue.esm";
import VueRouter from "vue-router/dist/vue-router.esm";
import axios from "axios";
import moment from "moment";
import { search_form } from "javascripts/mixins/search_form.js";
import { search_filters } from "javascripts/mixins/search_filters.js";
import { scroll_to_top } from "javascripts/mixins/scroll_to_top.js";
import eventResult from "javascripts/components/eventResult.js";

Vue.use(VueRouter);

document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementById("event-browser")) {
    axios.defaults.headers.common["X-CSRF-Token"] = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    const EventBrowser = Vue.component("event-browser", {
      mixins: [scroll_to_top, search_form, search_filters],
      components: {
        eventResult,
      },
      data() {
        return {
          favourites: [],
          favourites_enabled: false,
          favourites_apply_to: [],
          event_labels: null,
          is_mobile: window.innerWidth <= 768,
          pagination: {
            page: 1,
            per_page: 6,
          },
          search: {
            model: "events",
            path: "events",
            search_type: "search_event",
            search_url: "/events?page=1&per_page=10",
          },
          view_all_events: true,
          show_spinner: false,
          show_categories: false,
          current_category_name: "",
        };
      },
      methods: {
        triggerUpdateUrl() {
          this.search.search_url = this.updateUrl();
        },
        toggleCategories() {
          this.show_categories = !this.show_categories;
        },
        viewAllEvents() {
          this.view_all_events = true;
          this.current_category_name = "";

          this.clearCategories();
          this.loadData();

          this.show_categories = this.is_mobile ? false : true;
        },
        loadData() {
          this.search.results = [];
          this.triggerUpdateUrl();
          this.show_spinner = true;

          axios
            .get(`${this.search.path}.json`, {
              params: this.search.url_search_params,
            })
            .then((response) => {
              this.handleResponse(response);
              this.show_spinner = false;
              this.search.results = this.search.results.slice(0, 3);
              this.refreshing_filters = false;
              this.event_labels = response.data.event_labels;
              this.favourites = response.data.favourites;
              this.favourites_enabled = response.data.favourites_enabled;
              this.favourites_apply_to = response.data.favourites_apply_to;
            });
        },
        filterByCategory(category) {
          this.current_category_name = category.name;
          this.view_all_events = false;
          this.clearCategories();
          category.selected = !category.selected;
          this.loadData();

          this.show_categories = this.is_mobile ? false : true;
        },

        // TODO - This could be refactored to be more DRY.
        // Currently overrides the methods in the mixins.
        setDates(from, to) {
          this.search.start_date = new Date().fp_incr(from);
          this.search.end_date = new Date().fp_incr(to);
          this.loadData();
        },
        setThisWeek() {
          this.search.start_date = new Date().fp_incr(0);
          this.search.end_date = new Date().fp_incr(
            7 - this.search.start_date.getDay()
          );
          this.loadData();
        },
        setNextWeek() {
          this.search.start_date = new Date().fp_incr(
            7 - new Date().fp_incr(0).getDay() + 1
          );
          this.search.end_date = new Date().fp_incr(13);
          this.loadData();
        },
      },
      computed: {
        documentBody() {
          return document.getElementById("js-body");
        },
        itemIsFavourable() {
          return (
            this.favourites_enabled &&
            this.favourites_apply_to.includes("Events")
          );
        },
      },
      mounted() {},
      created() {
        this.loadData();

        addEventListener("resize", () => {
          this.is_mobile = innerWidth <= 768;
        });
      },
      filters: {
        formatDate(value) {
          if (value) {
            return moment(String(value)).format("LL");
          }
        },
      },
    });

    const router = new VueRouter({
      mode: "history",
      routes: [
        {
          path: "/events/*",
          component: EventBrowser,
        },
      ],
    });

    new Vue({
      el: "#event-browser",
    });
  }
});
